






































































































@import '@design';

.location--info {
  font-size: 1.2rem;
  font-weight: 300;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.03em;
  &.big {
    font-size: 1.5rem;
    line-height: 1.7;
  }
  .location--hours {
    white-space: pre;
  }
}
